.reservaBorradorContainer {
    margin: 2%;
}

.summaryBorradorContainer {
    margin: 2%;
}

.btnContainer {
    display: flex;
    justify-content: flex-end;
}

.guardarBtn {
    margin-right: 1%;
}