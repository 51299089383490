.mainTable .ant-pagination-options .ant-select-dropdown .rc-virtual-list-holder-inner > .ant-select-item-option:last-child {
    position: relative;
}

.mainTable .ant-pagination-options .ant-select-dropdown .rc-virtual-list-holder-inner > .ant-select-item-option:last-child .ant-select-item-option-content {
    color: transparent;
}

.mainTable .ant-pagination-options .ant-select-dropdown .rc-virtual-list-holder-inner > .ant-select-item-option:last-child::before {
    content: "TODOS";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}

.mainTable .ant-table-column-sorters .ant-table-column-sorter .ant-table-column-sorter-inner .anticon.active svg {
    fill: var(--primary-color);
}

.mainTable .ant-table-filter-column .ant-table-filter-trigger.active svg {
    fill: var(--primary-color);
}

.mainTable .ant-tag:before {
    content: "";
    padding-inline: 6px;
    font-size: 9px;
    border-radius: 50%;
    margin-right: 5px;
    opacity: .8;
}

.mainTable .ant-tag.bloqued:before, .mainTable .ant-tag.simple:before {
    display: none;
}

.mainTable .ant-tag {
    color: #515151 !important;
}

.mainTable .ant-tag:before {
    background-color: #515151 !important;
}

.mainTable .ant-tag.ant-tag-green {
    color: #389e0d !important;
}

.mainTable .ant-tag.ant-tag-green:before {
    background-color: #389e0d !important;
}

.mainTable .ant-tag.ant-tag-orange {
    color: #d46b08 !important;
}

.mainTable .ant-tag.ant-tag-orange:before {
    background-color: #d46b08 !important;
}

.mainTable .ant-tag.ant-tag-red {
    color: #cf1322 !important;
}

.mainTable .ant-tag.ant-tag-red:before {
    background-color: #cf1322 !important;
}

.mainTable .ant-tag.ant-tag-blue {
    color: #0958d9 !important;
}

.mainTable .ant-tag.ant-tag-blue:before {
    background-color: #0958d9 !important;
}

.mainTable .ant-tag.ant-tag-has-color.simpleTag {
    color: #FFF !important;
}