.calendarioContent {
  height: 550px;
}

/* Checkbox*/

.checkBoxContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* Search Bar */

.searchBarContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  /* padding: 1.5% 0% 1.5% 0%; */
}

.searchBarContent {
  display: flex;
}

/* Anotación button */

.anotacionesContainer {
  padding: 4% 1% 1% 1%;
  margin: 0 auto;
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.anotacionesBox {
  display: flex;
  width: 100%;
}

.anotacionesBtn {
  height: 50px;
}

.dateRange {
  display: flex;
}




.rangesContainer {
  width: 40%;
}

.canalContent {
  padding-left: 2%;
  width: 25%;
}

/* Tablas */

.tablasContainer {
  width: 80%;
  margin: 0 auto;
}
