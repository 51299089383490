.cardContainer {
  margin: 0.5%;
}

.cardBody {
  display: flex;
  justify-content: space-between;
  box-shadow: 10px 10px 5px rgba(80, 78, 78, 0.062);
}

.centrosContainer {
  display: flex;
}


.headerSelect {
  border-radius: 10px;
  padding: 2px;
  position: relative;
  right: 12px;
  border: 0px;
  outline: 0px;
}

.logoutIcon {
  padding-left: 25px;
  font-size: 22px;
  position: relative;
  top: 6.5px;
  color: red;
}
