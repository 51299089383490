.formContainer {
  padding: 1%;
}

.formContent {
  display: flex;
  width: 100%;
}

.One {
  width: 80%;
  padding: 1%;
}

.Two {
  width: 130%;
  padding: 2%;
}

.fullWidth {
  width: 500%;
}

/* Buttons */

.formBtnsContainer {
  padding: 1%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.añadirActividadBtn,
.backBtn,
.crearBorradorBtn,
.finalizarBtn {
  margin-right: 1%;
}

.submitButtonHideTemp {
  display: none;
}

.submitContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.calendarioTopLayer {
  border-radius: 10px;
  display: block;
  background-color: rgba(0, 0, 0, 0.125);
  pointer-events: none;
}

.warningNewDateSelection {
  padding: 1%;
  margin: 0 auto;
  width: 70%;
  border-radius: 20px;
  justify-content: center;
  text-align: center;
  background-color: rgb(247, 244, 241);
  margin-bottom: 1%;
}

.warningNewDateBtnContainer {
  padding-top: 1%;
  margin: 0 auto;
  width: 40%;
  display: flex;
  justify-content: space-around;
}
