.detallePagoContainer {
  margin: 0 auto;
  width: 80%;
  padding: 2%;
}

.precioTotal {
    padding: 3%;
    margin-left: 5%;
}

.detallePago {
  display: flex;
  margin-top: 15%;
}

.detallePagado {
  margin-left: 20%;
}

.btnContainer {
  width: 100%;
}

.devBtn {
  position: relative;
  top: 10px;height: 40px;

}

.cantidadInputsContainer {
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.detallePagoRestante {
display: flex;
margin-bottom: 3%;
}

.pagoRestante {
  width: 30%;
}
.formContainer {
  display: flex;
}

.formContent {
  display: flex;
}

.labels {
  width: 80%;
}

.btnsContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 3%;
}

.guardarBtn {
  margin-right: 1%;
}
