.navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.burguer-menu {
  font-size: 20px;
  position: relative;
  left: 18px;
  top: 2px;
}

.close-nav-btn {
  color: white;
  font-size: 25px;
  position: relative;
  left: 160px;
  top: -5px;
}

.nav-menu {
  z-index: 100;
  background-color: #3e3e3ff3;
  width: 230px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 950ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  list-style: none;
  height: 50px;
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.nav-text a:hover {
  background-color: #363636;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  z-index: 100;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menu-item {
  color: white !important;

  font-size: 15px;
}
