.resumenReservaContainer {
  padding: 25px 5px;
  border-radius: 20px;

  width: 100%;
  margin: 0 auto;
}

/*Datos personales*/
.datosContainer {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  padding: 10px 35px;
  background-color: rgba(187, 184, 184, 0.146);
  border-radius: 20px;
}

.titleResumen {
  font-size: 14px;
  font-weight: 500;
  margin-right: 5px;
}
.titles {
  font-size: 13px;
}

.modificarDatosPersonalesBtnContainer {
  display: flex;
  align-items: center;
}

/* .modDatosPersonalesBtn {
  position: relative;
  top: 130px;
  left: -20px;
} */

.summaryActivityContainer {
  width: 80%;
  border-radius: 10px;
  padding: 1%;
  justify-content: space-around;
  margin: 0 auto;
}

.selectedActivityContainer {
  width: 60%;
}

.buttonActivityContainer {
  display: flex;
  justify-content: space-around;
  width: 30%;
}

/*Servicios adicionales*/
.servicioAdicional {
  display: flex;
  width: 75%;
  justify-content: flex-end;
  padding-top: 1%;
  padding-bottom: 0.5%;
  margin: 0 auto;
}

.servicioAdicionalData {
  width: 75%;
}

.formServicioAdicional {
  margin-top: 2%;
  width: 96%;
  margin: 0 auto;
  padding: 2%;
}

.summaryActivity {
  padding: 2%;
}

.containerPagos {
  padding-top: 20px;
  width: 70%;

  display: flex;
  justify-content: space-around;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.containerPagos > div {
  width: 100%;
}

.totalPrecioReservaContent {
  background-color: rgba(149, 145, 145, 0.094);
  width: 50%;
  display: flex;
  border-radius: 10px;
  padding: 3%;
  align-items: center;
  justify-content: center;
}

.totalPrecioReservaContainer {
  position: relative;
  top: -65px;
  background-color: rgba(146, 145, 145, 0.618);
  padding: 20px;
  border-radius: 20px;
}

.btnTotales {
  position: relative;
  top: 20px;
}

/*Resumen pago*/

.paymentContent {
  background-color: rgba(144, 147, 146, 0.507);
  border-radius: 20px;
  padding: 10px;
}

.cantidadDelPago,
.fechaDelPago {
  display: flex;
}

.labelsPayment {
  width: 65%;
  position: relative;
  top: 15px;
  left: 20px;
}

.resumenContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.resumenContent {
  padding: 10px;
  width: 30%;
  background-color: white;
  border-radius: 20px;
}

.buttonContainerHistorial {
  padding: 1%;

  margin: 0 auto;
  width: 70%;
  padding-bottom: 50px;
}

.modificarPreciosBtn {
  margin-right: 1%;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
/* Método de pago*/

.totalPagoContainer {
  justify-content: center;
  width: 70%;
  margin: 0 auto;
  padding: 0 !important;
  padding-top: 2% !important;
  padding-bottom: 2% !important;
}

.actividadUnidad {
  background-color: rgba(128, 128, 128, 0.237);
  display: flex;
  justify-content: space-around;
  padding: 10px 10px;
  border-radius: 20px;
}
.serviciosAdBtnAct {
  height: 30px;
  position: relative;
  top: 15px;
}

.serviciosAdBtn {
  width: 90px;
  margin-left: 5px;
  font-size: 10px !important;
}

.serviciosAdicionalesContainer {
  width: 90%;
  margin: 0 auto;
}

.totalespago {
  display: flex;
  width: 100%;
}

.submitBtnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit {
  background-color: rgba(0, 128, 0, 0.87) !important;
  border: none !important;
}

/* edit personal data */

.formGroupEdit {
  padding: 10px 35px;
  background-color: rgba(187, 184, 184, 0.146);
  border-radius: 20px;
  width: 80%;
  margin: 0 auto;
}

.labels {
  padding: 0 !important;
  width: 100px;
  height: 15px;
  font-size: 14px;
  padding-left: 10px !important;
}

.inputs {
  padding: 0 !important;
  height: 20px;
  padding-left: 8px !important;
}

.displayformFlex {
  display: flex;
}

.editDatosPersonalesBtnContainer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: relative;
  left: -20px;
  padding-top: 5px;
}

.cancelBtn {
  margin-left: 3px;
}

.precioPagosStyles {
  width: 100%;
}

.añadirOtroPagoStyles {
  display: flex;
}

.paidAmount {
  padding: 1%;
  background-color: rgba(107, 218, 107, 0.445);
  border-radius: 20px;
}

.inputFormPayment {
  margin-top: 10px;
  width: 100px;

}
