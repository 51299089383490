.calendarContainer {
  width: 95%;
  margin: 0 auto;
}

/* selects */

.calendarDisplayContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.calendarDisplaySelect {
  width: 10% !important;
  margin: 1%;
  padding: 0.5% !important;
}

/* Checkbox Container */
.checkBoxContainer {
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: space-between;
}

/* Card Info Activity */

.activityCardInfo {
  padding: 12px 0 12px 0;
  display: flex;
  justify-content: space-around;
}

.activityCardContainer {
  background-color: rgba(7, 128, 7, 0.46);;
  padding: 15px;
  border-radius: 20px;
  height: 90px;
}

.anotacionesTitle {
  color: rgb(46, 44, 44);
  font-size: 15px;
  padding-left: 10px;
}

p {
  color: black;
}

.deleteAnotacionIcon {
  color: rgba(196, 12, 12, 0.733);
}

.deleteAnotacionIcon:hover {
  color: rgba(226, 9, 9, 0.644);
}

.calendarBtnsContainer {
  display: flex;
  height: 50px;
}

/* anotaciones*/

.anotacionesContainer {
  width: 60%;
  border-radius: 20px;
  padding: 10px;
  background-color: rgba(134, 6, 134, 0.249);

}

.anotacionesBtn {
  position: relative;
  left: -12px;
}

.anotacionesIcon {
  font-size: 28px;
  color: rgba(134, 6, 134, 0.76);
}

.anotacionesIcon:hover {
  font-size: 28px;
  color: rgba(134, 6, 134, 0.904);
}

.addReservaIcon {
  font-size: 28px;
  color: rgba(7, 128, 7, 0.671);
}

.addReservaIcon:hover {
  color: rgba(7, 128, 7, 0.815);
}

p {
  margin-bottom: 0 !important;
}

/* Anotaciones description */

.anotacionContainer {
  display: flex;
  background-color: rgba(255, 255, 255, 0.904);
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 2px;
  /* box-shadow: 2px 2px rgba(180, 25, 134, 0.507); */
}

.anotacionn {
  width: 95%;
}

.descripcionAnotacion .weather {
  font-size: 12px;
}

.description {
  font-weight: bold;
}

.anotacionesInputData {
  padding-left: 5;
}

/* subheader*/

.subheaderContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 0 20px 0;
}

/*checkboxes*/

.checkbox label {
  font-size: small;
  font-weight: 400;
  position: relative;
  top: -1px;
}
