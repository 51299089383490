.tablaContainer {
  width: 80%;
  margin: 0 auto;
}

.activityCardContainer {
  width: 20%;
  margin-top: 1%;
}

.tableFooter {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.crearReservaBtn {
  height: 50px;
  margin-top: 1%;
}

.searchBarContent {
  display: flex;
  height: 30px;
}

.rangesContainer {
  width: 90%;

  padding: 12px;
}

.btnEntradaSearch,
.btnReservaSearch {
  padding-left: 7px;
  padding-right: 7px;
  height: 23px;
  border: none;
  background-color: rgba(58, 57, 57, 0.616);
  position: relative;
  left: 12px;
  top: 2px;
}

.searchIcon {
  color: white;
  font-size: 8.5px;
  position: relative;
  top: -1px;
}

.checkbox label {
  font-size: small;
  font-weight: 400;
}

/* //date search inputs */

.dateInput {
  border: none !important;
  border-bottom: 1px solid grey !important;
  border-radius: 0px !important;
  padding: 0 !important;
  width: 140px !important;
  font-size: 13px !important;
  padding-left: 4px !important;
  color: grey !important;
}

.labelsDateRange {
  position: relative;
  top: 3px;
  width: 40%;
  font-size: 13px;
  color: rgb(63, 63, 63) !important;
}

.secondLabel {
  width: 30%;
  padding-left: 10%;
}

.date {
  display: flex;
}

.canalSelect {
  width: 100px;
  padding: 3px;
  font-size: 13px;
  color: rgb(63, 63, 63) !important;
}
