.infoContainer {
  width: 100%;
  /*padding-top: 1%;*/
  padding: 25px;
}

.modificarReservaContainer {
  width: 128%;
}

.datosDeReserva {
  display: flex;
  justify-content: space-between;

  width: 80%;
  margin: 0 auto;
}

.infoDeActividad {
  display: flex;
}

.deleteActividadnIcon {
  color: rgba(255, 0, 0, 0.578);
  position: relative;
  font-size: 13px;
  top: 11px;
  left: -32px;
}

.deleteActividadnIcon:hover {
  color: red;
}

.reservaTotales {
  display: flex;
}

.datos {
  width: 60%;
  position: relative;
}

.datos > button {
  position: absolute;
  top: 0;
  margin: 0 0 0 auto;
  right: 0;
  border: 0;
  background: none;
  text-align: right;
  left: unset;
}

.datos > button i {
  font-size: 20px;
}

.datosPersonalesBtns {
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
}

.datosPersonalesBtns > button:not(:first-child) {
  margin-top: 10px;
}

.datosPersonalesBtns > button {
  background-color: transparent;
  border-color: transparent;
  padding: 0;
}

.datosPersonalesBtns > button:hover,
.datosPersonalesBtns > button:focus,
.datosPersonalesBtns > button:active {
  border-color: transparent;
  opacity: .5;
}

.datosPersonalesBtns > button:first-child i {
  color: #38ba7c;
  font-size: 20px;
}

.datosPersonalesBtns > button:last-child i {
  color: #c36069;
  font-size: 20px;
}

.resumenPagos {
  width: 35%;
}

.parcial {
  background-color: rgba(255, 166, 0, 0.256);
  padding: 12px;
  border-radius: 20px;
  height: 220px;
}

.active {
  background-color: rgba(20, 247, 20, 0.141);
  padding: 20px 20px;
  border-radius: 10px;
  /* width: 40%; */
  height: 220px;
}

.btnContainerPagos {
  width: 100%;
  position: relative;
  top: 10%;
}

.noPay {
  background-color: rgba(255, 0, 0, 0.181);
  padding: 12px;
  border-radius: 10px;
  height: 220px;
}

.btnContainer {
  width: 30%;
  position: relative;
  top: 7px;
}

.infoDeActividad {
  width: 80%;
  margin: 0 auto;
  padding: 12px 20px;
  background-color: rgba(185, 183, 183, 0.187);
  border-radius: 10px;
  margin-top: 1%;
  align-items: center;
  position: relative;
}

.infoDeActividad .btnContainer {
  top: 0;
  text-align: right;
}

.infoDeActividad .btnContainer button {
  margin-right: 10px;
}

.infoDeActividad .btnContainer span {
  background-color: rgb(148, 148, 148);
  padding: 5px 13px;
  border-radius: 50%;
  position: relative;
}

.infoDeActividad .btnContainer span:hover {
  cursor: pointer;
}

.infoDeActividad .btnContainer span i {
  color: #FFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.infoDeActividad .btnContainer span:not(:first-child) {
  margin-left: 8px;
}

.infoDeActividad .btnContainer .deleteActivityBtn {
  background-color: #c36069;
}

.infoDeActividad .btnContainer .historicActivityBtn {
  background-color: #77b1c8;
}

.actividad {
  width: 80%;
}

.reservaTotales {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  width: 80%;
}

.totales {
  margin-top: 6%;
  border-radius: 20px;
  padding: 20px 30px;
  background-color: rgba(159, 159, 163, 0.253);
}

.totales button {
  margin-top: 10px;
}

.anotacionesContainer {
  padding: 20px 5px 0;
  width: 80%;
  display: flex;
  justify-content: flex-end;
  margin: auto;
}

.btnsContainer {
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 1%;
  border-top: 1px solid #d8d8d8;
  padding-top: 30px;
  margin-top: 18px;
}

.btnsContainer button:not(:first-child) {
  margin-left: 20px;
}

.btnContainerHistorial {
  position: relative;

  float: right;
}
.title, .title b {
  font-size: 15px;
}

h3 b {
  font-size: 2.3rem;
}

/* edit */

.placeholderModify {
  border: none;
  border-bottom: 1px solid grey;
  padding-left: 10px;
}

.placeholderModify:focus-visible {
  border-color: rgb(35, 78, 143);
  outline: none;
}

.addAnotherActBtnContainer {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  padding: 16px;
}

.calendarioAñadirActividad {
  position: relative;
  left: -55px;
}

/* //test hide actividad */

.hideActividad {
  display: none;
}

.disappearActividad {
  display: none;
}

.tarifaDetail {
  font-weight: bold;
  margin-top: 5px;
}
