.modificarPrecioContainer {
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin: 0 auto;
}

.btnsContainer {
  display: flex;
  justify-content: space-between;
  width: 16%;
  height: 40px;
}

.dd {
  display: flex;
  height: 35px;
}

.porcentaje {
  font-size: 22px;
}

.porCiento {
  width: 50%;
}

.saveBtn {
    float: right;
  height: 35px;
 
  margin-top: 5%;
}

.observacionesContainer {
    margin-top: 8%;
}
.observacionLbl {
    margin-right: 3%;
}


