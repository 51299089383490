.inputs {
  padding: 1px !important;
  padding-left: 12px !important;
}

.labels {
  padding: 0 !important;

  font-size: 12px;
}

/* Validation */
.requiredFieldStar {
  color: red!important;
  font-weight: 600;
}