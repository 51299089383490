.activityForm {
    background-color: ghostwhite;
    /* background-color: #f2f2f2; */
    padding: 20px;
    border-radius: 15px;
    margin-top: 10px;
    border: 1px solid #d8d8d8;
}

.activityForm .ant-divider .ant-divider-inner-text {
    font-size: 16px;
}

.activityFormFirst {
    padding-right: 20px;
}

.activityFormSecond {
    padding-left: 20px;
}

.activityFormFirst .ant-select-in-form-item {
    width: 100%;
}

.activityFormFirst .ant-input-number-in-form-item {
    width: 50px;
}

.activityFormFirst .ant-input-number .ant-input-number-handler-wrap,
.modalTarifas .ant-input-number .ant-input-number-handler-wrap {
    display: none;
}

.activityFormFirst .ant-input-number-group-addon .anticon.anticon-user {
    display: block;
}

.modalTarifas .okBtn {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.modalTarifas .okBtn span {
    color: #FFF !important;
}

.modalTarifas .okBtn:hover {
    background-color: var(--primary-color-dark);
    border-color: var(--primary-color-dark);
}

.modalTarifas .okBtn:disabled {
    opacity: .5;
}

.resumeRateBox {
    background-color: rgb(236, 236, 236);
    padding: 20px;
    border-radius: 10px;
    margin-top: 15px;
}

.resumeRateBox p, .resumeRateBox b {
    font-size: 14px;
}

.resumeFinishRateBox {
    background-color: rgb(236, 236, 236);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.resumeFinishRateBox .actionBtns {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.resumeFinishRateBox .actionBtns .iconCircle {
    background-color: #8c8c8c;
    border-radius: 50% !important;
    padding-inline: 6px;
    height: 27px;
}

.resumeFinishRateBox .actionBtns .iconCircle.editBtn {
    background-color: #5675ae;
}

.resumeFinishRateBox .actionBtns .iconCircle.deleteBtn {
    background-color: #ae565e;
}

.resumeFinishRateBox .actionBtns .iconCircle:not(:first-child) {
    margin-left: 5px;
}

.resumeFinishRateBox .actionBtns .iconCircle svg {
    font-size: 15px;
}

.resumeFinishRateBox .actionBtns .iconCircle.disabledClick {
    opacity: .5;
}

.resource-input-value .ant-input-number .ant-input-number-input {
    padding-inline: 5px;
    text-align: center;
    font-weight: bold;
}

.ant-select-dropdown #resources_list + .rc-virtual-list .ant-select-item-option-selected {
    opacity: .5;
    background-color: #ff4d4f33 !important;
}

.ant-select-dropdown #resources_list + .rc-virtual-list .ant-select-item-option-selected .ant-select-item-option-state svg {
    fill: var(--primary-color-dark);
}

.ant-select-dropdown #resources_list + .rc-virtual-list .ant-select-item-option-active,
.ant-select-dropdown #resources_list + .rc-virtual-list .ant-select-item-option:hover {
    background-color: transparent;
}
