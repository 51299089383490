.simpleTable.ant-table-wrapper .ant-table-thead > tr > th,
.simpleTable.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 5px 10px !important;
}

.simpleTable.ant-table-wrapper table {
  border-radius: 0;
}

.simpleTable.ant-table-wrapper .ant-table {
  border-radius: 0;
}

.simpleTable.ant-table-wrapper .ant-table-container {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.simpleTable.ant-table-wrapper .ant-table-tbody > tr:nth-child(2n+1),
.simpleTable.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover,
.simpleTable.ant-table-wrapper .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background-color: transparent;
}

.simpleTable.colored.ant-table-wrapper .ant-table {
  background-color: #a6daf2;
  font-weight: bold;
}

.simpleTable.colored.ant-table-wrapper .ant-table th {
  background-color: transparent !important;
}

.simpleTable.coloredTwo.ant-table-wrapper .ant-table {
  background-color: #dbf0fa;
}

.simpleTable.noBody.ant-table-wrapper .ant-table-tbody {
  display: none;
}

.simpleTable.invoice.colored.ant-table-wrapper .ant-table {
  background-color: #f2a6a6;
}

.simpleTable.invoice.coloredTwo.ant-table-wrapper .ant-table {
  background-color: #fadbdb;
}