.anotacionesInternasContainer {
  width: 100%;
  margin: 0 auto;
  margin-top: 3%;
  margin-bottom: 3%;
}

.observacionContent {
  margin: 1.5%;
}

.formContainer {
    margin-top: 7%;
}

.observacionForm {
    display: flex;
}

.observacionLabel {
    padding-right: 2.5%;
}

.buttonContainer {
    float: right;
}

.anotacionesItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.anotacionesItem:not(:last-child) {
  border-bottom: 1px solid #dcdcdc;
}

.trashIcon {
  position: relative;
  color: #8c8c8c;
  padding: 8px;
  border: 1px solid #8c8c8c;
  border-radius: 8px;
}

.trashIcon:hover {
  color: #ed5e68;
  cursor: pointer;
}

