:root{
  --primary-color: #ff4d4f;
  --primary-color-h: 359.3;
  --primary-color-s: 100%;
  --primary-color-l: 65.1%;
  --primary-color-hsl: var(--primary-color-h), var(--primary-color-s), var(--primary-color-l);
  --primary-color-dark: hsl(var(--primary-color-h), var(--primary-color-s), calc(var(--primary-color-l) + 8%));
  --primary-color-opacity-10: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 0.10);
  --primary-color-opacity-15: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 0.15);
  --primary-color-opacity-30: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 0.30);
  --primary-color-opacity-35: hsla(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l), 0.35);
}