.rbc-event.rbc-event-allday {
    height: 50px;
    margin-top: -19px;
    border-radius: 0;
    /*background-color: #ff2a2c99;*/
    background-color: #87d068b5;
    pointer-events: none;
}

.bookingForm .ant-form-item {
    margin-bottom: 8px;
}

.bookingForm .ant-form-item-label {
    padding-bottom: 4px;
}

.selectedDates .ant-select-selection-overflow {
    justify-content: center;
    margin-top: 18px;
}

.selectedDates .ant-select-selection-overflow .ant-select-selection-overflow-item {
    margin-inline: 3px;
    margin-bottom: 5px;
}

.selectedDates .ant-select-selection-overflow .ant-select-selection-overflow-item .ant-select-selection-item {
    border-bottom-color: #b7eb8f;
    background: #f6ffed;
}

.calendarCol {
    position: relative;
    padding: 20px;
}

.calendarCol .calendarOverlay {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #00000017;
    z-index: 5;
    border-radius: 8px;
}

.stepsForm .ant-steps-item-title {
    font-size: 14px;
    line-height: 18px;
}

.stepsForm .ant-steps-item-tail {
    top: 2px !important;
}

.stepsForm .ant-steps-icon svg {
    font-size: 22px;
}

.stepsForm .ant-steps-item-active .ant-steps-icon svg {
    fill: var(--primary-color);
}

.stepsForm .ant-steps-item-finish +
.ant-steps-item-active .ant-steps-icon svg {
    fill: #000;
}

.stepsForm .ant-steps-item-finish .ant-steps-icon svg {
    fill: var(--primary-color);
    opacity: .5;
}

.stepsForm .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: var(--primary-color);
    opacity: .5;
}

.dateTabs .ant-tabs-nav-wrap {
    /*justify-content: center;*/
}