.anotacionesContainer {
  width: 100%;
  float: right;
}

.formselect {
  width: 40px !important;
}

.buttonContainer {
  width: 100%;
  padding: 1% 3% 0% 0%;
  display: flex;
  justify-content: flex-end;
}

.saveBtn {
  margin-right: 1%;
}
