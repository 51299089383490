.personalData {
    background-color: #f5f5ff;
    border-radius: 10px;
    padding: 20px;
    margin-top: 58px;
    border: 1px solid lavender;
}

.personalData p, .personalData b {
    font-size: 14px;
}

.ant-row > .activityData:nth-child(1n+3) {
    margin-top: 20px;
}

.activityData {
    background-color: #f5f5ff;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid lavender;
}

.aditionalServices {
    margin: 15px 0;
}

.aditionalServices .ant-collapse {
    border: 1px solid #eaeaea;
}

.aditionalServices .ant-collapse-header {
    justify-content: center;
}

.aditionalServices .ant-collapse-header .ant-collapse-header-text {
    margin: 0 !important;
    flex: unset !important;
}

.servicesList {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 25px;
    margin-top: 30px;
}

.servicesList .serviceItem {
    background-color: lavender;
    padding: 10px 30px 10px 15px;
    border-radius: 8px;
    position: relative;
    max-width: 200px;
}

.servicesList .serviceItem .actionBtns {
    position: absolute;
    top: 0;
    right: -10px;
    display: flex;
    flex-direction: column;
    bottom: 0;
    justify-content: center;
    gap: 5px;
}

.servicesList .serviceItem .actionBtns .iconCircle {
    background-color: #8c8c8c;
    border-radius: 50% !important;
    padding-inline: 6px;
    height: 26px;
}

.servicesList .serviceItem .actionBtns .iconCircle.editBtn {
    background-color: #5675ae;
}

.servicesList .serviceItem .actionBtns .iconCircle.deleteBtn {
    background-color: #ae565e;
}

.servicesList .serviceItem .actionBtns .iconCircle svg {
    font-size: 14px;
}

.priceData {
    background-color: #fbf7f2;
    padding: 20px 20px 60px;
    border-radius: 10px;
    border: 1px solid antiquewhite;
}

.priceData .priceTotal,
.priceData .priceTotal b {
    font-size: 16px;
}

.priceData .priceTotal span {
    font-size: 20px;
}

.priceData .priceTotal span.changed {
    color: red !important;
    text-decoration: line-through;
}

.paymentData {
    background-color: #f5f5ff;
    padding: 20px;
    border: 1px solid lavender;
}

.listNotes {
    margin-top: 30px;
}

.listNotes .noteItem {
    background-color: #f5f5ff;
    padding: 20px;
    border: 1px solid lavender;
    border-radius: 10px;
    margin-bottom: 10px;
}

.listNotes .noteItem .iconCircle {
    background-color: #8c8c8c;
    border-radius: 50% !important;
    padding-inline: 7px;
    height: 30px;
}

.listNotes .noteItem .iconCircle.deleteBtn {
    background-color: #ae565e;
}

.listNotes .noteItem .iconCircle svg {
    font-size: 16px;
}