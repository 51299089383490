.reasignResourceForm h6 span {
    font-size: 16px;
}

.reasignResourceForm .ant-form-item-label label {
    font-weight: 400;
}

.reasignResourceForm #oldResource {
    color: #000;
    font-size: 12px;
}