.emptyTable table {
    min-height: 600px;
}

.emptyTable table .ant-empty-description span {
    color: #aaa !important;
}

.mainTable.emptyMainTable .ant-empty-description span {
    color: #c6c6c6 !important;
    font-weight: normal;
}

.mainTable.emptyMainTable table {
    min-height: 260px;
}

.borded-btn {
    background-color: var(--primary-color);
    padding: 5px 12px !important;
    height: auto;
    border-radius: 40px !important;
}
.borded-btn:hover {
    background-color: var(--primary-color-dark) !important;
}
.borded-btn span, .borded-btn p {
    color: #FFF !important;
}

.borded-btn span {
    margin-right: 5px;
    font-size: 20px;
    line-height: 12px;
}
.borded-btn.circle {
    padding: 7px !important;
}

.schedulerComp {
    margin-top: 20px;
    display: flex;
}

.schedulerComp > div:last-of-type {
    width: 100%;
}

.schedulerComp > div > div:first-child {
    display: none !important;
}

.schedulerComp .rs__outer_table > div {
    overflow-x: hidden;
}

/* .schedulerComp .rs__outer_table > div > div:first-child .css-fpqwey {
    grid-template-columns: 60px;
}

.schedulerComp .rs__outer_table > div > div:first-child .css-fpqwey > span:not(.rs__header) {
    display: none !important;
} */

.schedulerComp .rs__outer_table > div > div:first-child .css-fpqwey > .rs__cell:first-child,
.schedulerComp .rs__outer_table > div > div:first-child .css-fpqwey > .rs__cell:nth-child(2) {
    display: none !important;
}

.schedulerComp .rs__outer_table > div > div:not(:first-child) .css-fpqwey > .rs__cell:first-child,
.schedulerComp .rs__outer_table > div > div .css-fpqwey > .rs__header,
.schedulerComp .rs__outer_table > div > div:not(:first-child) .css-fpqwey > .rs__cell .css-xrjn1 > div:first-child {
    display: none !important;
}

.schedulerComp .rs__outer_table > div > div:not(:first-child) .css-fpqwey > .rs__cell .css-xrjn1 > div:last-of-type {
    width: calc(100% + 2px);
    margin-left: -2px;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey > .rs__cell .css-xrjn1 > div:last-of-type {
    border-color: #aa1815;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey > .rs__cell .css-xrjn1 > div:first-of-type {
    background: #aa1815;
}

/* .schedulerComp .rs__outer_table > div > div .css-fpqwey > .rs__cell .css-xrjn1 {
    margin-top: -9px;
} */

.schedulerComp .rs__outer_table > div > div .css-fpqwey {
    grid-template-columns: 1fr !important;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey {
    grid-template-columns: 60px repeat(1, 1fr);
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey .rs__cell {
    height: 60px !important;
}

.schedulerComp.half .rs__outer_table > div > div .css-fpqwey .rs__cell .rs__event__item {
    margin-top: -7px;
}

.schedulerComp.full .rs__outer_table > div > div .css-fpqwey .rs__cell .rs__event__item {
    margin-top: -2px;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey .rs__cell .rs__event__item h6,
.schedulerComp .rs__outer_table > div > div .css-fpqwey .rs__cell .rs__event__item p {
    color: #FFF;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey .rs__cell .rs__event__item {
    width: 100% !important;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey .rs__cell .rs__event__item .MuiButtonBase-root > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey > .rs__cell.rs__time span {
    font-size: 12px;
    letter-spacing: 0;
    font-family: unset;
}

.schedulerComp .schedulerEvent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 150px;
    margin: auto;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
}

.schedulerComp .schedulerEvent.overbooking {
    margin: unset;
}

.schedulerComp .schedulerEvent .eventStatus {
    position: absolute;
    top: -12px;
    left: 10px;
}

/* .schedulerComp .schedulerEvent.overbooking a {
    position: absolute;
    top: -10px;
    left: 10px;
} */

.schedulerComp .schedulerEvent .eventStatus a:not(:first-child) {
    margin-left: 3px;
}

.schedulerComp .schedulerEvent a svg {
    fill: #ff4d4f;
    font-size: 20px;
    background-color: #FFF;
    border: 1px solid #00000090;
    padding: 2px;
    border-radius: 50%;
}

.schedulerComp .schedulerEvent div {
    color: #FFF;
}

.schedulerComp .css-fpqwey .rs__cell .MuiButtonBase-root {
    pointer-events: none;
}

.schedulerComp .rs__outer_table > div > div .css-fpqwey .css-xrjn1 {
    z-index: 1;
}

.schedulerComp .rs__outer_table > div {
    overflow-x: auto;
}

.schedulerComp .rs__outer_table > div > div {
    min-width: 300px;
}

.mainTable .arrived svg,
.mainTable .annotations svg {
    font-size: unset;
    color: unset;
}
