 span {
  /*color: black!important;*/
 }

.pageContainer {
  /* width: 100vw;
  height: 100vh; */
  /* background-color: rgba(0, 0, 0, 0.374); */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer {
  width: 85%;
  background-color: rgb(255, 255, 255);
  padding: 2%;
  border-radius: 10px;
}

.numTotalContent {
  width: 50%;
}
.tablesContainer {
  background-color: rgba(230, 230, 230, 0.461);
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
  margin-top: 2%;
}

.btnContainer {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding-top: 5%;
}

.redCircle {
  color: red;
  padding-right: 1.5%;
  font-size: 11px;
}

.orangeCircle {
  color: orange;
  padding-right: 1.5%;
  font-size: 11px;
}
.greenCircle {
  color: green;
  padding-right: 1.5%;
  font-size: 11px;
}
.yellowCircle {
  color: yellow;
  padding-right: 1.5%;
  font-size: 11px;
}

/*****/
.specificsContent {
  margin-top: 4%;
}

.pestañasContainer {
  display: flex;
  justify-content: space-around;
}

.pestaña1,
.pestaña2,
.pestaña3 {
  display: flex;
  align-items: center;
  padding: 1%;

  border-radius: 2px;
  width: 150px;
}

.pestaña2 {
  background-color: rgba(0, 255, 94, 0.219);
}

.pestaña3,
.pestaña1
/* .pestaña2 */
{ 
  background-color: rgba(211, 205, 205, 0.402);
}

.reservasTableContainer {
  background-color: rgba(230, 230, 230, 0.461);
  padding: 2%;
  border-radius: 20px;
}

.buscadorContainer {
  display: flex;
  height: 10vh;
}
.checkboxContainer {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.checkboxes {
  display: inline;
  padding: 1%;
}

/* recursos */

.recursosContainer {
  display: flex;
  justify-content: space-around;
}

.reasignarSelectorContainer {
  padding: 2%;
}

.asignarRecursoContainer {
  padding-left: 2%;
  display: flex;
  justify-content: space-between;
  width: 40%;
}

/* GUIA */

.asignados {
  color: red;
}

.asignadosContainer,
.disponiblesContainer,
.extraContainer {
  display: flex;
  padding: 1.5%;
}

.guia {
  margin-right: 10px;
}
