.bookingModalRow .bookingTitle {
    font-size: 24px;
}

.bookingModalRow .ant-form-item {
    margin-bottom: 0;
}

.bookingModalRow .ant-form-item label {
    font-weight: bold;
}

.bookingModalRow .personalDataForm textarea {
    border-radius: 0;
    border-bottom: 1px solid #d0d0d0;
}

.bookingModalRow .personalDataForm textarea:focus {
    border-color: var(--primary-color);
}

.bookingModalRow .personalDataForm textarea[disabled] {
    border-color: transparent;
}

.bookingModalRow .bookingInfo,
.bookingModalRow .personalDataInfo {
    background-color: #f5f5ff;
    border: 1px solid lavender;
    padding: 20px;
    border-radius: 10px;
}

.bookingModalRow .infoHeader {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d6d6dc7d;
}

.bookingModalRow .infoHeader h4 {
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0;
}

.bookingModalRow .bookingInfo .ant-input[disabled],
.bookingModalRow .personalDataInfo .ant-input[disabled] {
    color: #000000e0;
    cursor: default;
}

.bookingModalRow .paymentInfo {
    padding: 20px;
    border-radius: 10px;
    border: 1px solid;
}

.bookingModalRow .paymentInfo.parcial {
    background: #fff7e6;
    border-color: #ffd591;
}

.bookingModalRow .paymentInfo.unpaid {
    background: #fff1f0;
    border-color: #ffa39e;
}

.bookingModalRow .paymentInfo.paid {
    background: #f6ffed;
    border-color: #b7eb8f;
}

.bookingModalRow .paymentInfo.return {
    background: #e6f4ff;
    border-color: #91caff;
}

.bookingModalRow .paymentInfo.canceled {
    background: #e6e6e6;
}

.bookingModalRow .paymentInfo.bloqued {
    background: #fffcfa;
    border-color: #f5b798;
}

.bookingModalRow .paymentInfo p,
.bookingModalRow .paymentInfo b,
.bookingModalRow .paymentInfo span {
    font-size: 14px;
}

.bookingModalRow .paymentInfo p.pending {
    font-size: 16px;
    /* font-weight: bold; */
}

.bookingModalRow .paymentInfo p.pending span {
    font-weight: 500;
    font-size: 20px;
}

.bookingModalRow .paymentInfo p.unpaid span {
    color: #cf1322 !important;
}

.bookingModalRow .paymentInfo p.parcial span {
    color: #d46b08 !important;
}

.bookingModalRow .paymentInfo p.paid span {
    color: #389e0d !important;
}

.bookingModalRow .paymentInfo p.return span {
    color: #0958d9 !important;
}

.bookingModalRow .activitiesList {
    margin-top: 20px;
}

.bookingModalRow .activitiesList .activityItem {
    background-color: #f5f5ff;
    border: 1px solid lavender;
    padding: 20px;
    border-radius: 10px;
}

.bookingModalRow .activitiesList .activityItem:not(:first-child) {
    margin-top: 20px;
}

.bookingModalRow .activitiesList .activityItemCol {
    background-color: #f5f5ff;
}

.bookingModalRow .activitiesList .activityItemCol:not(:first-child) {
    margin-top: 20px;
}

.bookingModalRow .activitiesList .activityItemCol h4 b {
    font-size: 1.5rem !important;
}

.bookingModalRow .activitiesList .activityItemCol .activityItemColDetail > .ant-col > p.title {
    margin-bottom: 4px !important;
}

.bookingModalRow .activitiesList .activityItem p,
.bookingModalRow .activitiesList .activityItem b {
    font-size: 14px;
}

.totalBooking {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    min-height: 200px;
}

.totalBooking h3 {
    font-size: 1.9rem;
    text-transform: uppercase;
}

.totalBooking h3 b {
    font-size: 2.4rem;
}

.totalBooking .totalPrices h4 {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.totalBooking .totalPrices h4 span {
    font-size: 1.5rem;
}

.bookingModalRow button.ant-btn.iconCircle {
    border-radius: 50% !important;
    padding-inline: 6px;
    height: 30px;
}

.bookingModalRow button.ant-btn.iconCircle svg {
    font-size: 15px;
}

.bookingModalRow .activityActions > button {
    margin-right: -50px;
}

.bookingModalRow .activityActions > button:not(:first-child) {
    margin-top: 5px;
}

.paymentListModal .paymentItem {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid;
}

.paymentListModal .paymentItem.paid {
    background: #f6ffed;
    border-color: #b7eb8f;
}

.paymentListModal .paymentItem.return {
    background: #fff7e6;
    border-color: #ffd591;
}

.servicesList {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    gap: 25px;
    flex-wrap: wrap;
}

.servicesList .serviceItem {
    background-color: lavender;
    padding: 10px 30px 10px 15px;
    border-radius: 8px;
    position: relative;
    max-width: 200px;
}

.servicesList .serviceItem .actionBtns {
    position: absolute;
    top: 0;
    right: -10px;
    display: flex;
    flex-direction: column;
    bottom: 0;
    justify-content: center;
    gap: 5px;
}

.servicesList .serviceItem .actionBtns .iconCircle {
    background-color: #8c8c8c;
    border-radius: 50% !important;
    padding-inline: 6px;
    height: 30px;
}

.servicesList .serviceItem .actionBtns .iconCircle.editBtn {
    background-color: #5675ae;
}

.servicesList .serviceItem .actionBtns .iconCircle.deleteBtn {
    background-color: #ae565e;
}

.servicesList .serviceItem .actionBtns .iconCircle svg {
    font-size: 14px;
}

form#services {
    padding: 20px;
    border-radius: 10px;
    border: 1px solid transparent;
}

form#services.modeEdit {
    background: #fff7e6;
    border-color: #ffd591 !important;
}

.historicBookingList {
    margin-top: 20px;
}

.historicBookingList .historicBookingItem {
    background-color: #f5f5ff;
    border: 1px solid lavender;
    padding: 10px 20px;
    border-radius: 10px;
}

.iconPromotionAct {
    position: absolute;
    top: -17px;
    right: -21px;
    background-color: var(--primary-color);
    border-radius: 50%;
    padding: 2px;
    fill: #FFF !important;
}