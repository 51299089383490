.carouselContent {
  border-radius: 12px;
  padding: 5px;
  background-color: #80808310;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.carouselDates {
  display: flex;
  justify-content: center;
  position: relative;

  top: 3px;
}

.date {
  width: 95px;
  color: #656567;
  font-weight: 450;
  text-align: center;
}

.date:hover {
  cursor: pointer;
}

.todayDate {
  width: 97px;
  color: #656567;
  font-weight: bolder;
  text-align: center;
}

/* carousel arrows */

.arrows {
  font-size: 25px;
  color: #656567;
  position: relative;
  margin-left: 10px;
}

.dateBtn {
  margin-left: 2px;
  margin-right: 2px;
 height: 18px;
 border: none;
 background-color: rgba(205, 205, 206, 0.274);
}
