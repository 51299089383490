.header {
    margin-bottom: 20px;
}
.icon svg {
    font-size: unset;
    color: unset;
}
.button {
    background-color: var(--primary-color);
    padding: 5px 12px !important;
    height: auto;
    border-radius: 40px !important;
}
.button:hover {
    background-color: var(--primary-color-dark) !important;
}
.button span, .button p {
    color: #FFF !important;
}

.button span {
    margin-right: 5px;
    font-size: 20px;
    line-height: 12px;
}