.historialContainer {
  padding: 2% !important;
  margin: 0 auto;
  width: 100%;
}

.historialContent {
 margin: 0 auto;
  display: flex;
  justify-content: space-around;
  width: 90%;
  background-color: rgba(165, 167, 167, 0.185);
  padding: 5px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.observacionBtn {
  position: relative;
  top: 27px;
}

.labelshistorialPago {
  font-size: 14px;
  font-weight: 500;
}

.infoPayment {
  font-size: 14px;
  font-weight: 400;
}
.textoObservaciones {
  width: 80%;
  padding-top: 2%;
  padding-bottom: 2%;
  margin: 0 auto;
}

.closeBtn {
  padding: 0 !important;
  width: 30px;
  position: relative;

  left: 94%;
  color: white !important;
  background-color: red !important;
  border: none !important;
}
.textoObservacion {
  margin-top: 1%;
}

.lastPrice {
  margin-left: 2% !important;
  color: green;
}
