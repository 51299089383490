.toPrintBooking * {
  font-family: 'Barlow';
}

.bono-footer p, .bono-footer span {
  font-size: 11px;
  font-weight: 500;
}

.bono-footer small {
  font-size: 10px;
  color: gray;
}

.bono-footer span.marker {
  color: #06c;
}

.bono-footer p strong {
  font-size: 11px;
  font-weight: 600;
}

.bono-footer ul {
  margin-top: 1rem;
}