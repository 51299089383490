.iconCircle {
    border-radius: 50% !important;
    height: 30px;
    width: 30px;
}

.iconCircle svg {
    font-size: 16px !important;
}

.iconCircle.icon-24 svg {
    font-size: 24px !important;
}

.formSerachBooking {
    display: flex;
    gap: 10px;
}

.mainTable .ant-table .ant-table-tbody .ant-table-row.owner-b2b {
    background-color: var(--primary-color-opacity-10);
}

.mainTable .ant-table .ant-table-tbody .ant-table-row.owner-b2b:hover > td {
    background-color: var(--primary-color-opacity-15);
}

.mainTable .ant-table .ant-table-tbody tr:nth-child(2n+1).owner-b2b,
.mainTable .ant-table .ant-table-tbody tr:nth-child(2n+1).owner-b2b:hover > td {
    background-color: var(--primary-color-opacity-15);
}

.dark-mode .mainTable .ant-table .ant-table-tbody .ant-table-row.owner-b2b {
    background-color: var(--primary-color-opacity-30);
}

.dark-mode .mainTable .ant-table .ant-table-tbody .ant-table-row.owner-b2b:hover > td {
    background-color: var(--primary-color-opacity-35);
}

.dark-mode .mainTable .ant-table .ant-table-tbody tr:nth-child(2n+1).owner-b2b,
.dark-mode .mainTable .ant-table .ant-table-tbody tr:nth-child(2n+1).owner-b2b:hover > td {
    background-color: var(--primary-color-opacity-35);
}