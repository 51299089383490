section.dark-view * {
  color: #fff;
}

section.dark-view.ant-layout .ant-layout-sider,
section.dark-view.ant-layout .ant-layout-header,
section.dark-view .ant-menu-dark {
    background: var(--dark-secondary);
}

section.dark-view.ant-layout .ant-layout-sider-trigger {
  background: var(--dark-trigger);
}

section.dark-view .ant-layout {
  background: var(--dark-primary);
}

section.dark-view .ant-table-wrapper .ant-table,
section.dark-view .ant-table-wrapper .ant-table th {
  background-color: var(--dark-secondary) !important;
}

section.dark-view .ant-table tr:nth-child(2n+1) {
  background-color: #373e45;
}

section.dark-view .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td,
section.dark-view .ant-table-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background-color: #373e45;
}

section.dark-view .ant-table-wrapper .ant-table-thead > tr > th {
  color: #FFF;
}

section.dark-view .ant-table thead tr:first-child {
  background-color: transparent;
}

section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
  border-inline-end-color: #4f555c;
  border-bottom: 1px solid #4f555c;
}

section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td:last-child,
section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th:last-child,
section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th:last-child,
section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td:last-child {
  border-inline-end-color: transparent;
}

section.dark-view .ant-table-wrapper .ant-table-summary {
  background-color: transparent;
}
section.dark-view .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td {
  border-inline-end-color: #4f555c;
}

section.dark-view .ant-select-selector,
section.dark-view .ant-picker,
section.dark-view .ant-btn-default,
section.dark-view .ant-input,
section.dark-view .ant-pagination-item {
  background-color: var(--dark-selects-bg) !important;
  border-color: #68686899 !important;
}

section.dark-view .ant-input::placeholder,
section.dark-view .ant-picker-input input::placeholder {
  color: #ffffff8f;
}

section.dark-view .ant-picker input,
section.dark-view .ant-form-item .ant-form-item-label > label {
  color: #FFF;
}

section.dark-view .ant-select-single.ant-select-open .ant-select-selection-item {
  color: #FFF;
}

.dark-mode .ant-select-dropdown {
  background-color: var(--dark-secondary);
  border: 1px solid #6f7479;
}

.dark-mode .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #4f555c;
}

.dark-mode .ant-select-dropdown .ant-select-item-option-content,
.dark-mode .ant-select-dropdown .ant-select-item,
.dark-mode .ant-select-dropdown .ant-select-item-empty .ant-empty-description {
  color: #FFF;
}

.dark-mode .ant-picker-dropdown .ant-picker-panel-container {
  background-color: var(--dark-secondary);
}

.dark-mode .ant-picker-dropdown .ant-picker-header button > span,
.dark-mode .ant-picker-dropdown .ant-picker-header button,
.dark-mode .ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
  color: #FFF;
}

.dark-mode .ant-picker-dropdown .ant-picker-body thead tr th {
  background-color: #4f555c !important;
  color: #FFF;
}

.dark-mode .ant-picker-dropdown .ant-picker-body tbody tr:nth-child(2n+1) {
  background-color: #ddd8d80d;
}

.dark-mode .ant-collapse .ant-collapse-content {
  background-color: #ffffff0a;
}

.dark-mode .ant-collapse > .ant-collapse-item {
  border-bottom: none;
}

.dark-mode .ant-checkbox .ant-checkbox-inner {
  background-color: transparent;
}

.dark-mode .ant-table-cell .ant-tag.bloqued {
  background-color: #eee;
}

/* MODAL */

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content {
  background-color: #454a4f;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-modal-close svg {
  fill: #FFF;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-modal-body *,
.dark-mode .bookingModalRow .bookingInfo .ant-input[disabled],
.dark-mode .bookingModalRow .personalDataInfo .ant-input[disabled] {
  color: #FFF;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .bookingModalRow .bookingInfo .ant-input[disabled],
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .bookingModalRow .personalDataInfo .ant-input[disabled] {
  background-color: transparent !important;
  opacity: .8;
  border-bottom: none;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-modal-body .ant-checkbox-disabled + span {
  color: rgba(225, 255, 255, 0.25);
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-modal-body .ant-btn-default:disabled {
  opacity: .5;
}

.dark-mode .bookingModalRow .bookingInfo,
.dark-mode .bookingModalRow .personalDataInfo,
.dark-mode .bookingModalRow .activitiesList .activityItemCol {
  background-color: #a6a6ca45;
  border: 1px solid #e6e6fa5c;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .bookingModalRow .bookingInfo .ant-input,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .bookingModalRow .personalDataInfo .ant-input {
  background-color: #a6a6ca45 !important;
  border-bottom: 1px solid #d0d0d0;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-divider {
  border-block-start: none;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-divider.ant-divider-with-text::before,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-divider.ant-divider-with-text::after {
  opacity: .1;
}

.dark-mode .bookingModalRow .paymentInfo.paid {
  background: #f6ffed9c;
  border-color: #b7eb8f8f;
}

.dark-mode .bookingModalRow .paymentInfo p.paid span {
  color: #c1fda7 !important;
}

.dark-mode .bookingModalRow .paymentInfo.unpaid {
  background: #86625f8f;
  border-color: #99726e;
}

.dark-mode .bookingModalRow .paymentInfo p.unpaid span {
  color: #ff98a0 !important;
}

.dark-mode .bookingModalRow .paymentInfo.parcial {
  background: #776a4d94;
  border-color: #a6956f;
}

.dark-mode .bookingModalRow .paymentInfo p.parcial span {
  color: #ffc58f !important;
}

.dark-mode .bookingModalRow .paymentInfo.bloqued {
  background: #d7a68561;
  border-color: #d7a68582;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-select-selector,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-picker,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-btn-default:not(.main-btn, .iconCircle, .accept-btn),
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-pagination-item {
  background-color: #3e4144 !important;
  border-color: #68686899 !important;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-select-single.ant-select-disabled,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-number-disabled,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-disabled {
  opacity: .4;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-btn-default span {
  color: #FFF;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input::placeholder,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-picker-input input::placeholder {
  color: #ffffff8f;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .rbc-off-range-bg {
  opacity: .2;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .rbc-header + .rbc-header,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #939393;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .rbc-month-view {
  border: 1px solid #939393;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .rbc-month-row + .rbc-month-row {
  border-top: 1px solid #939393;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .rbc-header {
  border-bottom: 1px solid #939393;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .rbc-today {
  background-color: #eaf6ff5e;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .calendarCol .calendarOverlay {
  background-color: var(--dark-secondary);
  opacity: .6;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .calendarCol .ant-form-item .ant-select-selection-item-content {
  color: #2d2f31 !important;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .activityForm {
  background-color: var(--dark-secondary);
  border: 1px solid #d8d8d826;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-number-group .ant-input-number-group-addon,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-number-disabled,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-number {
  background-color: #3e4144 !important;
  border-color: #68686899 !important;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-number .ant-input-number-handler-wrap {
  background: #3e4144;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler {
  border: none;
  height: 50%;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-input-number .ant-input-number-handler-wrap .ant-input-number-handler:hover {
  height: 50%;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .resumeRateBox,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .finishActivity {
  background-color: var(--dark-secondary);
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .resumeFinishRateBox {
  /*background-color: var(--dark-secondary);*/
  background-color: #474e55;
  border: 1px solid #5d6064;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .resumeFinishRateBox .ant-btn-default {
  background-color: #545657 !important;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ant-steps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: rgba(255, 255, 255, 0.21);
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .ratesActivity > div > div {
  background-color: #474e55;
  border: 1px solid #5d6064;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .activityData,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .personalData,
.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .paymentData {
  background-color: #9090bd2e;
  border: 1px solid #ddddec2e;
  border-radius: 10px;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .priceData {
  background-color: #ceac8136;
  border: 1px solid #faebd77d;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .aditionalServices .ant-collapse {
  border: 1px solid #eaeaea69;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .aditionalServices .ant-collapse .ant-collapse-content {
  background-color: #535763;
  padding-top: 14px;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .stepsForm .ant-steps-item-finish + .ant-steps-item-active .ant-steps-icon svg {
  fill: #FFF;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .servicesList .serviceItem {
  background-color: #bfbff975;
}

.dark-mode .react-confirm-alert-body {
  background-color: #454a4f;
  color: #DDD;
  border: 1px solid #5d6266;
}

.dark-mode .react-confirm-alert-body h1 {
  color: #FFF;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .listNotes .noteItem {
  background-color: #bfbff975;
  border: none;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .paymentListModal .paymentItem.paid {
  background: #f6ffed66;
  border-color: #f6ffed99;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .paymentListModal .paymentItem.return {
  background: #bdaf90b2;
  border-color: #e4d0a6;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content .historicBookingList .historicBookingItem {
  background-color: #9595b740;
  border: 1px solid #9595b7a3;
}

.dark-mode .ant-modal:not(.booking-print) .ant-modal-content form#services.modeEdit {
  background: #8a7d6073;
  border-color: #9d8c67 !important;
}

/* Badges */

.dark-mode .ant-tag-green {
  background: #f6ffed1f;
}

.dark-mode .mainTable .ant-tag.ant-tag-green {
  color: #b7eb8f !important;
}

.dark-mode .mainTable .ant-tag.ant-tag-green::before {
  background-color: #b7eb8f !important;
}

.dark-mode .ant-tag-orange {
  background: #fff7e626;
}

.dark-mode .mainTable .ant-tag.ant-tag-orange {
  color: #ffd591 !important;
}

.dark-mode .mainTable .ant-tag.ant-tag-orange::before {
  background-color: #ffd591 !important;
}

.dark-mode .ant-tag-red {
  background: #fff1f01c;
}

.dark-mode .mainTable .ant-tag.ant-tag-red {
  color: #ffa39e !important;
}

.dark-mode .mainTable .ant-tag.ant-tag-red::before {
  background-color: #ffa39e !important;
}

.dark-mode .ant-table-cell .ant-tag.bloqued {
  background-color: #eeeeee12;
}

.dark-mode .ant-table-cell .ant-tag.bloqued svg path {
  fill: #d9d9d9;
}

.dark-mode .mainTable .ant-tag {
  color: #d9d9d9 !important;
}

.dark-mode .mainTable .ant-tag::before {
  background-color: #d9d9d9 !important;
}

.dark-mode .ant-picker-clear .anticon-close-circle svg {
  background-color: var(--dark-selects-bg);
}

.dark-mode .ant-picker-clear .anticon-close-circle svg path {
  background-color: #e2e4e5;
}

.dark-mode .ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.dark-mode .ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-range-start::before,
.dark-mode .ant-picker-dropdown .ant-picker-panel .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  background: var(--primary-color-opacity-35) !important;
}
