.reservaComponent {
  width: 100%;
  padding: 2%;
  background-color: rgba(95, 92, 92, 0.062);

  margin: 0 auto;
}

.formContent {
  width: 65%;
  margin: 0 auto;
}

.selectGroups {
  background-color: rgba(95, 92, 92, 0.199);
  padding: 25px;
  border-radius: 20px;
  margin-bottom: 18px;
}

.divFlex {
  display: inline-block;
}

.aiFillPlusCircle {
  font-size: 30px;
  position: relative;
  right: 50px;
  top: 28px;
}

.adultSelectContainer {
  padding: 2.5%;
}

.saveAdultsBtn {
  width: 20px;
}

.kidsBtnContainer {
  margin: 0 auto;
  width: 100% !important;
  align-items: center !important;
  justify-content: space-around;
  margin-top: 5% !important;
  padding: 2%;
}

.kidsBtnContent {
  display: flex;
  padding: 2%;
  width: 90%;
  justify-content: space-around;
}

.otro {
  display: none;
}

.horas {
  display: flex;
}

.dateStyle {
  display: flex;
}

.label {
  width: 100%;
  margin-left: 8px;
  position: relative;
  top: 8px;
  padding-bottom: 5px;
  font-size: 12px;
}

.fechasSeleccionadas {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding-bottom: 3%;
}

.paxTarifaSelected {
  display: flex;
  justify-content: space-around;
  background-color: rgba(94, 94, 94, 0.228);
  width: 5%;
  border-radius: 10px;
  padding: 1%;
}

.formTarifaContent {
  display: flex;
}

.precio {
  position: relative;
  top: 6px;
}

.priceInput {
  width: 70px !important;
}

.tarifaLabel {
  position: relative;
  top: 8px;
  margin-right: 8px;
}

.botonesActividad {
  display: flex;
  justify-content: center;
  justify-content: space-around;
  padding-top: 10%;
}

.actividadSeleccionada {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 10px;
  background-color: rgba(94, 94, 94, 0.123);
  padding: 1%;
  border-radius: 10px;
}

.totalPeople {
  padding: 2%;
}

.totalPersonas {
  background-color: rgba(94, 94, 94, 0.228);
  border-radius: 10px;
  margin: 1%;
  padding: 1%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.totalPersonasInfo {
  width: 100%;
}

.totalPersonasBotones {
  width: 35%;
  display: flex;
  justify-content: space-around;
}

.numPersonasContent {
  display: flex;
  width: 31%;
  justify-content: space-between;
}

.saveRecursosBtn {
  position: relative;
  left: -10px;
  float: right;
  border: 0px solid black;
  background-color: rgba(11, 126, 20, 0.514);
  padding: 4px;

  margin-right: 10px;
}

.selectedRecurso {
  margin-top: 3px;
  padding: 8px 8px;
  padding-left: 10px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.699);
}

.inputsRecursos {
  width: 100%;
  padding: 15px;
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.363);
}

.recursosInputs {
  width: 100%;
  margin: 0 auto;
}

.inputRecursosInfo {
  text-align: center;
}

.colorRecursoDis {
  font-weight: bold;
  display: flex;
  justify-content: space-around;
}
.numeroDePersonas {
  color: green !important;
}

/* Totales tarifa */
.totalesTarifa {
  padding-bottom: 5px;
}

/* Flujo mas de una actividad */
.btnGreen {
  background-color: green;
  color: #ffffff;
}

.addAnotherActivityBtnContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}
.addAnotherActivityBtn {
  margin-top: 10px;
  text-align: center;
  background-color: green !important;
  color: white !important;
}

.actividadGuardadaFlujo {
  display: flex;
  justify-content: space-around;
  background-color: rgba(0, 0, 255, 0.082);
  border-radius: 15px;
  padding: 1%;
  margin-bottom: 10px;
}

.fechaContainer {
  width: 20%;
  display: flex;
  justify-content: space-between;
}

.infoActividadYTarifas {
  padding-left: 1%;

  width: 40%;
}

.actividadTitle {
  font-weight: bold;
}

.btnsContainerTarifa {
  display: flex;
  width: 60%;
  justify-content: space-around;
  padding-top: 6px;
  padding-right: 15px;
  margin: 0 auto;
}

.tarifasFinal {
  padding: 2px 2px;
}

/* .deleteActividadContainer {
  display: flex;
  justify-content: flex-end;
  width: 80%;
} */

.trashIcon {
  position: relative;
  top: 10px;
  left: 10px;
}
.dateInfoWarning {
  background-color: rgb(241, 215, 206);
  text-align: center;
  padding: 1%;
  border-radius: 20px;
  width: 65%;
  margin: 1% auto;
}

.warningInfoContainerBtns {
  padding: 2% 1%;
  width: 60%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
}

.testColour {
  background-color: aqua;
}

.finalBtns {
  display: flex;
}

.tarifasInfo {
  display: flex;
  padding-left: 6%;
}
.precioYUdsInfo {
  padding-left: 6%;
}

.backBtnContainer {
  display: flex;
  justify-content: flex-end;
  padding-right: 5%;
  padding-top: 1%;
}

.tarifaNamePriceContainer {
  padding: 1%;
}

.modifyPriceContainer {
  display: flex;
  justify-content: space-between;
  /* width: 30%; */
  padding: 1%;
}

/* .discountSEction {
  padding: 1%;
} */

.porcentaje {
  padding: 1%;
}

.porcentajeContainer {
  padding-top: 10px;
  display: flex;
}

.discountBtns {
  padding: 1%;
  display: flex;
  margin: 0 auto;
  width: 50%;
  justify-content: space-between;
}

.newPriceContainer {
  display: flex;
}

.newPriceInput {
  height: 30px;
}
.newPriceLabel {
  width: 60%;
  position: relative;
  top: 3px;
}
.precioModified {
  text-decoration: line-through;
  color: red;
}

.newPriceAplied {
  background-color: rgba(26, 239, 26, 0.32);
  padding: 1%;
  border-radius: 20px;
  width: 35%;
  text-align: center;
}

.percentageSpans {
  background-color: rgba(26, 239, 26, 0.32);
  padding: 1%;
  margin-right: 1%;
  width: 10%;
  text-align: center;
  height: 30px;
}

.pricesUpdates {
  background-color: aquamarine;
  display: flex;
}

.noThroughLine {
  text-decoration: none !important;
}

.tarifaNameAndPrice {
  display: flex;
}

.tarifaTrash {
  position: relative;
  top: 8px;
  color: rgba(255, 0, 0, 0.74);
}

.tarifaTrash:hover {
  color: red;
}

.modificarTarifaContainer {
  display: flex;
  justify-content: space-around;
  background-color: #ffffffa8;
  padding: 1%;
  margin: 1%;

}

.eliminarBtn {
  margin-left: 5px;
}
